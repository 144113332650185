import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const HaarausfallBeiMannUndFrau = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Haarausfall bei Mann und Frau"
			noSlider
			{...props}
		>
			<p>
				Ein vorübergehender oder auch langfristiger Haarausfall der
				Kopfbehaarung ist für viele Menschen ein erschreckendes und auch
				sehr belastendes Ereignis.
			</p>
			<p>
				Vielfältig sind die Ursachen, eine gründliche und genaue
				Abklärung unumgänglich mit gründlicher Anamnese,
				Laborkontrollen, ggf. Haarwurzeluntersuchungen, ggf. mit der
				Entnahme einer kleinen Hautprobe, in örtlicher Betäubung der
				Kopfhaut, für eine histologische Untersuchung.
			</p>
			<p>
				Neben der speziellen Behandlung mit Tinkturen und Tabletten
				bieten sich ergänzende Behandlungen mit Mesotherapie und/oder
				Mikroneedling, auch in Kombination mit Plateled Rich Plasma, an.
			</p>

			<Questions title="Haarausfall bei Mann und Frau" prefix="um den" />
		</Layout>
	);
};

export default HaarausfallBeiMannUndFrau;
